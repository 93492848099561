.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; 
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.title {
  margin: 0;
  color: white;
}

.secondary-title {
  text-align: center;
  color: #BCC6CC;
}

.input-container {
  margin: .5rem 0 1.5rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: #BCC6CC;
}

.radio-option input[type="radio"] {
  margin-right: 0.5rem;
}

.checkbox-option input[type="checkbox"] {
  margin-right: 0.5rem;
}

.selected {
  color: #008000;
}

.main-label {
  font-size: 1.5rem;
  font-weight: bold;
  color:white;
}

.additional-requests {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rounded-textarea {
  border-color: #BCC6CC;
  border-width: medium;
  border-radius: .5rem; 
  text-align: center;
  font-size: 1rem;
  resize: none;
  width: 20rem;
  height: 2.5rem;
  margin: .5rem 0 1.5rem;
}

.normal-button {
  background-color: #BCC6CC;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: .5rem; 
  padding: .75rem;
  cursor: pointer;
}

.green-button {
  background-color: darkgreen;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: .5rem; 
  padding: .75rem;
  cursor: pointer;
}

.response {
  margin-top: 2rem;
  font-size: 1rem;
  font-weight: bold;
  color:white;
  width: 80%;
}